import React from "react";
import MainTitleBlackTextSearch from "../GeneralComponents/MainTitleBlackTextSearch";
import MainBoxContentInnerList from "../layouts/MainBoxContentInnerList";

class MainBoxOtherPanel extends React.Component {
  render() {
    return (
      <div className="main-box-content">
        <MainTitleBlackTextSearch>{this.props.title}</MainTitleBlackTextSearch>
        <div className="main-box-content__inner ">
          <MainBoxContentInnerList />

          <div className="main-box-content__inner-container scrollable-area">
            <div className="container ">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainBoxOtherPanel;
