import React from "react";
import TitleConstructor from "../../ElementsConstructor/TitleConstructor";
import MobileTitleConstructorSecond from "../../ElementsConstructor/MobileTitleConstructorSecond";
import FormFooterConstructorSecond from "../../ElementsConstructor/FormFooterConstructorSecond";
import BuildItemsConstructor from "../../ElementsConstructor/BuildItemsConstructor";
import BuildItemsInnerBoxConstructorMain from "../../ElementsConstructor/BuildItemsInnerBoxConstructorMain";
import BuildTitleHeader from "../../ElementsConstructor/BuildTitleHeader";
import FinishDoorBtn from "../../ElementsConstructor/FinishDoorBtn";
import FormFooterConstructorFurnitureLeftOrRight from "./FormFooterConstructorFurnitureLeftOrRight";
import PreviewDoor from "../../ElementsConstructor/PreviewDoor";

class FurnitureLeftOrRight extends React.Component {
  componentDidMount() {
    this.props.setShowImageMobile(false);
    this.props.setLoader(false);
  }

  render() {
    return (
      <>
        <TitleConstructor title="Hinge Side" classAdd="white" />

        <MobileTitleConstructorSecond title="When viewing the door from the outside" />

        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructorMain
            classAdd="hinge-side col-2 medium-height contain"
            classAddBox="mobile col2"
          >
            <div
              className={
                "build__item hinge-side build__marker-1 " +
                (this.props.side === "right" ? "active" : "")
              }
              onClick={() => {
                this.props.changeSide("right");
              }}
            >
              <div className="build__item-img ">
                <img
                  src={
                    require("./../../../assets/images/Screenshot_1.jpg").default
                  }
                />
              </div>
              <div className="build__item-name">Left Hinge</div>
            </div>
            <div
              className={
                "build__item hinge-side build__marker-1 " +
                (this.props.side === "left" ? "active" : "")
              }
              onClick={() => {
                this.props.changeSide("left");
              }}
            >
              <div className="build__item-img ">
                <img
                  src={
                    require("./../../../assets/images/Screenshot_2.jpg").default
                  }
                />
              </div>
              <div className="build__item-name">Right Hinge</div>
            </div>
          </BuildItemsInnerBoxConstructorMain>
        </BuildItemsConstructor>
        {/* <FinishDoorBtn greenButtonHandler={this.props.greenButtonHandler} /> */}
        <FormFooterConstructorFurnitureLeftOrRight
          classAdd="hide-btn"
          link="/frame-style-second"
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          greenButtonHandler={this.props.greenButtonHandler}
        />
        <PreviewDoor
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          setShowImageMobile={this.props.setShowImageMobile}
          setOrder={this.props.setOrder}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
        />
      </>
    );
  }
}

export default FurnitureLeftOrRight;
