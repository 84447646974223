import React from 'react'
import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import MobileTitleConstructorSecond from '../../ElementsConstructor/MobileTitleConstructorSecond'
import FormFooterConstructorSecond from '../../ElementsConstructor/FormFooterConstructorSecond'
import BuildItemsConstructor from '../../ElementsConstructor/BuildItemsConstructor'
import BuildItemsInnerBoxConstructorMain from '../../ElementsConstructor/BuildItemsInnerBoxConstructorMain'
import BuildTitleHeader from '../../ElementsConstructor/BuildTitleHeader'
import FormFooterConstructorFurnitureColorHandle from './FormFooterConstructorFurnitureColorHandle'
import PreviewDoor from '../../ElementsConstructor/PreviewDoor'
import PreviewFurnitureGroup from '../FurnitureGroup/PreviewFurnitureGroup'
import OverlayBuilderValidation from '../../OverlayBuilderValidation/OverlayBuilderValidation'

class FurnitureColorHandle extends React.Component {
  render() {
    console.log(this.props)
    return (
      <>
        <TitleConstructor title="Furniture" classAdd="white" />
        <OverlayBuilderValidation
          modalMessage="Please select a handle before proceeding"
          status={this.props.isHandleError}
          setStatus={this.props.setIsHandleError}
        >
          <MobileTitleConstructorSecond
            title={`${this.props.activeHandleBrand.brand} Range Choose your door handle`}
          />
          <BuildItemsConstructor>
            <BuildItemsInnerBoxConstructorMain
              classAdd="col-2 medium-height"
              classAddBox="mobile col2"
            >
              {this.props.handles.map((handle, index) => {
                return (
                  <div
                    className={
                      'build__item build__marker-1 ' +
                      (this.props.checkLayerInModel(handle).length > 0
                        ? 'active'
                        : '')
                    }
                    key={`handle_${index}`}
                    onClick={() => {
                      this.props.chooseComponent(handle)
                    }}
                  >
                    <div className="build__item-img ">
                      <img src={handle.preview} />
                    </div>
                    <div className="build__item-name">{handle.name}</div>
                  </div>
                )
              })}
            </BuildItemsInnerBoxConstructorMain>
          </BuildItemsConstructor>
        </OverlayBuilderValidation>

        <FormFooterConstructorFurnitureColorHandle
          link="/frame-style-second"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
        />
        <PreviewFurnitureGroup
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          setShowImageMobile={this.props.setShowImageMobile}
          setOrder={this.props.setOrder}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
          backButtonHandler={this.props.backButtonHandler}
        />
      </>
    )
  }
}

export default FurnitureColorHandle
