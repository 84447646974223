import React from "react";

import { NavLink } from "react-router-dom";

class BuildDoorBoxItem extends React.Component {
  render() {
    return (
      <div className="build-door__box-item">
        <NavLink
          to={`/consumer-flow-constructor/${this.props.item.formData.id}`}
        >
          <div className="build-door__box-item-title">{111}</div>
          <div className="build-door__box-item-img">
            <img src={this.props.item.formData.image} alt="" />
          </div>
          <div className="build-door__box-item-link">
            START BUILDING MY DREAM DOOR
          </div>
        </NavLink>
        <div className="build-btn popup-btn">
          <div className="build-btn-img">
            <img
              src={require("../../assets/images/arrow-black.svg").default}
              alt=""
            />
          </div>
          <div className="build-btn-text">LET’S BUILD</div>
        </div>
      </div>
    );
  }
}

export default BuildDoorBoxItem;
