import React from "react";
import TitleConstructor from "../../ElementsConstructor/TitleConstructor";
import MobileTitleConstructorSecond from "../../ElementsConstructor/MobileTitleConstructorSecond";
import FormFooterConstructorSecond from "../../ElementsConstructor/FormFooterConstructorSecond";
import BuildItemsConstructor from "../../ElementsConstructor/BuildItemsConstructor";
import BuildItemsInnerBoxConstructor from "../../ElementsConstructor/BuildItemsInnerBoxConstructor";
import BuildItemBuildMarker1 from "../../ElementsConstructor/BuildItemBuildMarker-1";
import FormFooterConstructorDoorStyle from "./FormFooterConstructorDoorStyle";
import PreviewDoor from "../../ElementsConstructor/PreviewDoor";
import PreviewDoorStyle from "./PreviewDoorStyle";

class DoorStyleSecond extends React.Component {
  titleCase = (string) => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  };
  render() {
    return (
      <>
        <TitleConstructor
          title={`${this.titleCase(this.props.brand)} Doors`}
          classAdd="white"
        />
        <MobileTitleConstructorSecond
          title={`Scroll to see our range of ${this.titleCase(
            this.props.brand
          )} door styles`}
        />

        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructor>
            {this.props.parts.map((part, index) => {
              return (
                <BuildItemBuildMarker1
                  model={this.props.model}
                  checkLayerInModel={this.props.checkLayerInModel}
                  key={`part_${index}`}
                  part={part}
                  chooseComponent={this.props.chooseComponent}
                />
              );
            })}
          </BuildItemsInnerBoxConstructor>
        </BuildItemsConstructor>
        <FormFooterConstructorDoorStyle
          link="/frame-style-second"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
        />
      </>
    );
  }
}

export default DoorStyleSecond;
