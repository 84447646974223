import React from "react";

class HeaderMenu extends React.Component {
  render() {
    return (
      <nav className="header__menu ">
        <ul className="menu__list ">
          <li>
            <a href="/build-door" className="menu__list-link">
              BUILD A DOOR
            </a>
            <div className="menu__list-link-drop-down ">
              <ul>
                <li>
                  <a href="#">APEER TRADITIONAL</a>
                </li>
                <li>
                  <a href="#">APEER CONTEMPORARY</a>
                </li>
                <li>
                  <a href="#">APEER SILKA</a>
                </li>
                <li>
                  <a href="#">APEER MODO</a>
                </li>
                <li>
                  <a href="#">APEER STABLE DOORS</a>
                </li>
                <li>
                  <a href="#">APEER FRENCH DOORS</a>
                </li>
                <li>
                  <a href="#">APEER 44 TRADITIONAL</a>
                </li>
                <li>
                  <a href="#">APEER ACCESSIBILITY DOORS</a>
                </li>
                <li>
                  <a href="#">DOUBLE DIAMOND DOORS</a>
                </li>
                <li>
                  <a href="#">DIAMOND DOORS</a>
                </li>
                <li>
                  <a href="#">OPEN OUT RANGE</a>
                </li>
              </ul>
              <div className="view-all">
                <a href="build-door.html">VIEW ALL</a>
              </div>
            </div>
          </li>
          <li>
            <a
              href="/my-virtual-showroom"
              className="menu__list-link list-link"
            >
              MY VIRTUAL SHOWROOM
            </a>
          </li>
          <li>
            <a href="/most-popular-doors" className="menu__list-link">
              MOST POPULAR DOORS
            </a>
            <div className="menu__list-link-drop-down">
              <ul>
                <li>
                  <a href="#">APEER TRADITIONAL</a>
                </li>
                <li>
                  <a href="#">APEER CONTEMPORARY</a>
                </li>
                <li>
                  <a href="#">APEER SILKA</a>
                </li>
                <li>
                  <a href="#">APEER MODO</a>
                </li>
                <li>
                  <a href="#">APEER STABLE DOORS</a>
                </li>
                <li>
                  <a href="#">APEER FRENCH DOORS</a>
                </li>
                <li>
                  <a href="#">DOUBLE DIAMOND DOORS</a>
                </li>
                <li>
                  <a href="#">HAPPY VALENTINES</a>
                </li>
                <li>
                  <a href="#">DOOR OF THE MONTH</a>
                </li>
                <li>
                  <a href="#">PASTEL COLOURS</a>
                </li>
                <li>
                  <a href="#">BRIGHT COLOURS</a>
                </li>
              </ul>
              <div className="view-all">
                <a href="most-popular-doors.html">VIEW ALL</a>
              </div>
            </div>
          </li>
          <li>
            <a href="/consumer-leads" className="menu__list-link">
              PROJECT DASHBOARD
            </a>
            <div className="menu__list-link-drop-down">
              <ul>
                <li>
                  <a href="/consumer-leads ">CONSUMER LEADS</a>
                </li>
                <li>
                  <a href="/sales-quotes">SALES QUOTES</a>
                </li>
                <li>
                  <a href="/sales-orders">SALES ORDERS</a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a href="#" className="menu__list-link list-link">
              OPTIONS
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default HeaderMenu;
