import React, { Component } from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHomeSecond from "../BuildDoor/BackHomeSecond";
import MainBoxConstructor from "../layouts/MainBoxConstructor";
import LeftPartConstructor from "../ElementsConstructor/LeftPartConstructor";
import RightPartConstructor from "../ElementsConstructor/RightPartConstructor";
import ImageConstructor from "../ElementsConstructor/ImageConstructor";
import FooterImagePanelConstructor from "../ElementsConstructor/FooterImagePanelConstructor";
import MyDreamDoor from "../ConsumerFlow/MyDreamDoor/MyDreamDoor";
import { connect } from "react-redux";
import { loadProduct } from "../../redux/actions/products";
import SeeOnMyHouse from "../SeeOnMyHouse/SeeOnMyHouse";
import { toPng } from "html-to-image";
import { getAllHouses } from "../../redux/actions/houses";
import ThankYou from "../ConsumerFlow/ThankYou/ThankYou";
import { saveOrderProduct } from "../../redux/actions/orders";

class ProductCheckout extends Component {
  state = {
    page: "checkout",
    product: {},
    formData: {
      name: "",
      address: "",
      post_code: "",
      phone_number: "",
      email: "",
      product_id: null,
    },

    is_loader: true,
    seeMyDoor: false,
    houses: [],
    activeHouse: {},
    myDoorEditor: false,
  };
  componentDidMount() {
    this.loadProduct(this.props.match.params.id);
  }

  loadProduct = (id) => {
    this.props.loadProduct(id).then((response) => {
      this.setState({
        product: { ...response.data },
        formData: { ...this.state.formData, product_id: response.data.id },
      });
    });
  };
  nameOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        name: e.target.value,
      },
    });
  };

  addressOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        address: e.target.value,
      },
    });
  };

  postcodeOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        post_code: e.target.value,
      },
    });
  };

  mobileOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        phone_number: e.target.value,
      },
    });
  };

  emailOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        email: e.target.value,
      },
    });
  };

  saveOrder = () => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props.saveOrderProduct(this.state.formData).then((response) => {
          this.setState({
            is_loader: false,
            page: "summary",
          });
        });
      }
    );
  };

  setSeeMyDoor = () => {
    this.setState({
      seeMyDoor: true,
    });
  };

  loadHouses = () => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props.getAllHouses().then((response) => {
          let activeHouse = response.data.length > 0 ? response.data[0] : {};
          this.setState({
            houses: [...response.data],
            activeHouse,
            is_loader: false,
          });
        });
      }
    );
  };

  showSeeMyDoor = (product) => {
    this.setState({
      seeMyDoor: true,
      activeProduct: { ...product },
    });
  };
  setActiveHouse = (house) => {
    this.setState({
      activeHouse: house,
    });
  };

  hideSeeMyDoor = () => {
    this.setState({
      seeMyDoor: false,
      activeHouse: {},
      myDoorEditor: false,
    });
  };

  downloadHouse = () => {
    this.setState({ is_loader: true }, () => {
      toPng(document.getElementById("html2png")).then((dataUrl) => {
        // console.log(dataUrl);
        const link = document.createElement("a");
        link.download = "my-door.png";
        link.href = dataUrl;
        link.click();
        this.setState({
          is_loader: false,
        });
      });
    });
  };

  setMyDoorEditor = (status) => {
    this.setState({
      myDoorEditor: status,
    });
  };

  render() {
    return (
      <>
        {this.state.seeMyDoor ? (
          <SeeOnMyHouse
            loadHouses={this.loadHouses}
            houses={this.state.houses}
            activeHouse={this.state.activeHouse}
            setActiveHouse={this.setActiveHouse}
            setSeeMyDoor={this.setSeeMyDoor}
            myDoorEditor={this.state.myDoorEditor}
            setMyDoorEditor={this.setMyDoorEditor}
            image={this.state.product.image}
            downloadHouse={this.downloadHouse}
            hideSeeMyDoor={this.hideSeeMyDoor}
          />
        ) : (
          <Main header={true}>
            <MainBoxAsite>
              <BackHomeSecond title="BACK TO DOOR STYLES" />
            </MainBoxAsite>
            <MainBoxConstructor>
              <LeftPartConstructor>
                {this.state.page === "checkout" ? (
                  <MyDreamDoor
                    nameOnChangeHandler={this.nameOnChangeHandler}
                    addressOnChangeHandler={this.addressOnChangeHandler}
                    postcodeOnChangeHandler={this.postcodeOnChangeHandler}
                    mobileOnChangeHandler={this.mobileOnChangeHandler}
                    emailOnChangeHandler={this.emailOnChangeHandler}
                    formData={this.state.formData}
                    saveOrder={this.saveOrder}
                    setSeeMyDoor={this.setSeeMyDoor}
                  />
                ) : null}
                {this.state.page === "summary" ? (
                  <ThankYou setSeeMyDoor={this.setSeeMyDoor} />
                ) : null}
              </LeftPartConstructor>
              <RightPartConstructor>
                {Object.keys(this.state.product).length > 0 ? (
                  <ImageConstructor
                    image={this.state.product.image}
                    greenButtonHandler={this.props.greenButtonHandler}
                  />
                ) : null}

                <FooterImagePanelConstructor
                  classAdd="disabled"
                  setOrder={this.props.setOrder}
                  order={this.props.order}
                />
              </RightPartConstructor>
            </MainBoxConstructor>
          </Main>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    loadProduct: (id) => {
      return dispatch(loadProduct(id));
    },
    getAllHouses: () => {
      return dispatch(getAllHouses());
    },
    saveOrderProduct: (formData) => {
      return dispatch(saveOrderProduct(formData));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductCheckout);
