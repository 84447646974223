import React from "react";
import Header from "../GeneralComponents/Header/Header";
import FooterPanelSecond from "../ElementsConstructor/FooterPanelSecond";
import { withRouter } from "react-router-dom";

function Main(props) {
  return (
    <div className="App">
      <div className="main">
        {props.header ? <Header /> : null}
        <div
          className={
            "main-box" +
            " " +
            (props.classAdd !== undefined ? props.classAdd : "")
          }
        >
          {props.children}
        </div>
        {Object.keys(props.match).length > 0 &&
        props.match.url === "/summary" ? (
          <FooterPanelSecond />
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(Main);
