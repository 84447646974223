import React from "react";

class BuildDoorBoxItemSecond extends React.Component {
  render() {
    return (
      <div className="build-door__box-item">
        <div className="build-door__box-item-img">
          <img src={this.props.item.formData.image} alt={""} />
        </div>
        <div className="box-item__showroom-con">
          <div className="box-item__showroom-title">WILTON</div>
          <div className="box-item__showroom-number">SFG7 SB27 £2560</div>
          <div className="box-item__showroom-link">
            <a
              href={`/consumer-flow-constructor/${this.props.item.formData.id}`}
            >
              VIEW SPEC
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default BuildDoorBoxItemSecond;
