import React from "react";
import { Link } from "react-router-dom";

class BackHome extends React.Component {
  render() {
    return (
      <div className="back-home">
        <Link to="/">
          <div className="back-home-img">
            <img
              src={require("../../assets/images/arrow-white.svg").default}
              alt=""
            />
          </div>
          <div className="back-home-title">home</div>
        </Link>
      </div>
    );
  }
}

export default BackHome;
