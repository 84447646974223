/* global $ */
import React from "react";
import { withRouter } from "react-router-dom";

class FormFooterConstructorFurnitureTypeKnocker extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div
        className={
          "build__bottpanel stage-2" +
          " " +
          (this.props.classAdd !== undefined ? this.props.classAdd : "")
        }
      >
        <div className="build__bottpanel-btnback">
          <a
            className="btn-1"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (!this.props.showImageMobile) {
                this.props.backButtonHandler();
              } else {
                this.props.setShowImageMobile(false);
              }
            }}
          >
            {"< BACK"}
          </a>
        </div>
        <div className="build__bottpanel-btnfs">
          {this.props.showImageMobile ? (
            <button className="btn-1" onClick={() => {}} />
          ) : null}
        </div>
        <div className="build__bottpanel-btnsummary">
          <a
            className="btn-2"
            href="#"
            onClick={(event) => {
              this.props.greenButtonHandler();
            }}
          >
            {"SKIP >"}
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(FormFooterConstructorFurnitureTypeKnocker);
