import axios from "axios";

export function getPartsOfBrand(brand) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(mainUrl + `/api/parts/brands/${brand}`);
    return response;
  };
}

export function getFurnitureParts(category, parent_id, doorStyle) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      mainUrl +
        `/api/parts/furniture/${category}/${parent_id}/door-style/${doorStyle}`
    );
    return response;
  };
}

export function getHandelBrands(brand, doorStyle) {
  console.log(doorStyle);
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      mainUrl + `/api/handels/category/${brand}/door-style/${doorStyle}`
    );
    return response;
  };
}

export function getHandelsByModel(model) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(mainUrl + `/api/handels/list/${model}`);
    return response;
  };
}

export function getGlassByModel(door, frame) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      mainUrl + `/api/glasses/glass-groups/${door}/${frame}`
    );
    return response;
  };
}

export function getFrameGlasses(frame) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      mainUrl + `/api/glasses/frame-glasses/${frame}`
    );
    return response;
  };
}

export function getFurnitureGroup(brand) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(mainUrl + `/api/furniture-group/${brand}`);
    return response;
  };
}
