/* global $ */
import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SimpleBar from "simplebar-react";

class BuildItemsInnerBoxConstructorMain extends Component {
  render() {
    return (
      <div
        className={
          "build__items-inner-box" +
          " " +
          (this.props.classAddBox !== undefined ? this.props.classAddBox : "")
        }
      >
        <SimpleBar className="scrollable-area" forceVisible="y">
          <div
            className={
              "build__items-inner" +
              " " +
              (this.props.classAdd !== undefined ? this.props.classAdd : "")
            }
          >
            {this.props.children}
          </div>
        </SimpleBar>
      </div>
    );
  }
}

export default BuildItemsInnerBoxConstructorMain;
