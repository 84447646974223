import React from "react";

class MainBoxConstructor extends React.Component {
  render() {
    return (
      <div className="main-box-content">
        <div className={"main-box-content__inner "}>{this.props.children}</div>
      </div>
    );
  }
}

export default MainBoxConstructor;
