import React from "react";

class HeaderInnerInfo extends React.Component {
  render() {
    return (
      <div className="header__inner-info">
        <span href="#">PROJECT REF NO. 0000007</span>
        <span href="#">DATE MODIFIED: 10/10/21</span>
      </div>
    );
  }
}

export default HeaderInnerInfo;
