import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHome from "../BuildDoor/BackHome";
import MainBoxOtherPanel from "../layouts/MainBoxOtherPanel";
import BuildDoorBoxItemOnlyImg from "../MostPopularDoors/BuildDoorBoxItemOnlyImg";
import ScrollTopBottom from "../layouts/scrollTopBottom";

class MostPopularDoors extends React.Component {
  render() {
    return (
      <Main header={true}>
        <ScrollTopBottom />
        <MainBoxAsite>
          <BackHome />
        </MainBoxAsite>
        <MainBoxOtherPanel title={"Most Popular Doors"}>
          <div className="build-door__box most-popular ">
            <BuildDoorBoxItemOnlyImg
            // key={`brands_${index}`}
            // brand={brand}
            />
          </div>
        </MainBoxOtherPanel>
      </Main>
    );
  }
}

export default MostPopularDoors;
