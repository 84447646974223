import React from 'react'

import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import MobileTitleConstructorSecond from '../../ElementsConstructor/MobileTitleConstructorSecond'
import FormFooterConstructorSecond from '../../ElementsConstructor/FormFooterConstructorSecond'
import BuildItemsConstructor from '../../ElementsConstructor/BuildItemsConstructor'
import BuildItemsInnerBoxConstructorMain from '../../ElementsConstructor/BuildItemsInnerBoxConstructorMain'
import BuildTitleHeader from '../../ElementsConstructor/BuildTitleHeader'
import FormFooterConstructorFurnitureTypeHandle from './FormFooterConstructorFurnitureTypeHandle'
import PreviewDoor from '../../ElementsConstructor/PreviewDoor'
import PreviewFurnitureGroup from '../FurnitureGroup/PreviewFurnitureGroup'
import OverlayBuilderValidation from '../../OverlayBuilderValidation/OverlayBuilderValidation'

class FurnitureTypeHandle extends React.Component {
  componentDidMount() {
    this.props.loadHandelBrands('silka')
    this.props.setShowImageMobile(false)
  }

  render() {
    return (
      <>
        <TitleConstructor title="Furniture Ranges" classAdd="white" />
        <OverlayBuilderValidation
          modalMessage="Please select a handle range before proceeding"
          status={this.props.isHandleBrandError}
          setStatus={this.props.setIsHandleBrandError}
        >
          <MobileTitleConstructorSecond title="Scroll to see our ranges of furniture" />
          <BuildItemsConstructor>
            <BuildItemsInnerBoxConstructorMain
              classAdd="col-2 medium-height"
              classAddBox="mobile col2"
            >
              {this.props.handelBrands.map((handelBrand, index) => {
                return (
                  <div
                    className={
                      'build__item build__marker-1 ' +
                      (this.props.activeHandleBrand.brand === handelBrand.brand
                        ? 'active'
                        : '')
                    }
                    key={`handel-${index}`}
                    onClick={() => {
                      this.props.chooseHandelBrand(handelBrand)
                    }}
                  >
                    <div className="build__item-img">
                      <img src={handelBrand.preview} />
                    </div>
                    <div className="build__item-name">{handelBrand.brand}</div>
                  </div>
                )
              })}
            </BuildItemsInnerBoxConstructorMain>
          </BuildItemsConstructor>
        </OverlayBuilderValidation>

        <FormFooterConstructorFurnitureTypeHandle
          link="/frame-style-second"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
        />
        <PreviewFurnitureGroup
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          setShowImageMobile={this.props.setShowImageMobile}
          setOrder={this.props.setOrder}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
        />
      </>
    )
  }
}

export default FurnitureTypeHandle
