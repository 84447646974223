import React, { Component } from 'react'
import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import BuildItemsConstructor from '../../ElementsConstructor/BuildItemsConstructor'
import SimpleBar from 'simplebar-react'
import { withRouter } from 'react-router-dom'
import { allSettings } from './../../../redux/actions/settings'
import { connect } from 'react-redux'
class ThankYou extends Component {
  state = {
    settings: {},
  }

  componentDidMount() {
    this.props.allSettings().then((response) => {
      this.setState({
        settings: { ...response },
      })
    })
  }

  render() {
    console.log(!this.props.match.params.iframePartnerPath)
    return (
      <>
        <TitleConstructor title="My dream door" classAdd="white" />
        <div
          className="panel-title"
          onClick={(event) => {
            event.preventDefault()
            this.props.setSeeMyDoor(true)
          }}
        >
          VIEW ON MY HOUSE +
        </div>
        <BuildItemsConstructor>
          <SimpleBar className="scrollable-area" forceVisible="y">
            <div className="details-form ">
              <div className="details-form__inner-box">
                <div className="thankyou-box">
                  <div className="thankyou-box-logo">
                    <a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="92.526"
                        height="40.455"
                        viewBox="0 0 92.526 40.455"
                      >
                        <g
                          id="Сгруппировать_788"
                          data-name="Сгруппировать 788"
                          transform="translate(-15648.237 -1531.806)"
                        >
                          <path
                            id="Контур_124"
                            data-name="Контур 124"
                            d="M15648.237,1531.806v40.455h10.065v-34.227l12.528-6.228Z"
                            fill="#000"
                          />
                          <path
                            id="Контур_125"
                            data-name="Контур 125"
                            d="M15691.606,1546.777a4.76,4.76,0,0,0-4.317,2.359h-.055v-1.944h-3.782v20.617h3.978v-7.255h.057a5.069,5.069,0,0,0,4.344,2.3c4.231,0,6.363-3.8,6.363-7.875,0-4.336-2.046-8.2-6.587-8.2m-.841,12.92c-2.579,0-3.477-2.359-3.477-4.838s.842-4.924,3.449-4.924c2.55,0,3.474,2.5,3.474,4.924,0,2.479-.841,4.838-3.446,4.838"
                            fill="#000"
                          />
                          <path
                            id="Контур_126"
                            data-name="Контур 126"
                            d="M15706.992,1546.777c-4.4,0-7.4,3.481-7.4,8.053,0,4.72,2.831,8.024,7.4,8.024,3.277,0,5.661-1.533,6.781-5.135h-3.5a3.261,3.261,0,0,1-3.137,1.978c-2.215,0-3.449-1.208-3.559-3.864h10.452c.279-4.691-2.1-9.056-7.034-9.056m-3.418,6.4a3.221,3.221,0,0,1,3.306-3.242c1.934,0,2.8,1.118,3.166,3.242Z"
                            fill="#000"
                          />
                          <path
                            id="Контур_127"
                            data-name="Контур 127"
                            d="M15722.824,1546.777c-4.4,0-7.4,3.481-7.4,8.053,0,4.72,2.831,8.024,7.4,8.024,3.278,0,5.661-1.533,6.782-5.135h-3.5a3.265,3.265,0,0,1-3.137,1.978c-2.214,0-3.448-1.208-3.561-3.864h10.453c.279-4.691-2.1-9.056-7.034-9.056m-3.419,6.4a3.222,3.222,0,0,1,3.306-3.242c1.934,0,2.8,1.118,3.169,3.242Z"
                            fill="#000"
                          />
                          <path
                            id="Контур_128"
                            data-name="Контур 128"
                            d="M15739.9,1546.777a5.123,5.123,0,0,0-4.624,3.247h-.059v-2.834h-3.782v15.25h3.981v-6.871c0-2.685,1.007-4.9,3.923-4.9a6.916,6.916,0,0,1,1.428.151v-3.9a2.874,2.874,0,0,0-.867-.147"
                            fill="#000"
                          />
                          <path
                            id="Контур_129"
                            data-name="Контур 129"
                            d="M15670.5,1541.37l-7.605,21.052h4.469l2.189-6.639h6.174l2.214,6.591h4.593l-7.487-21Zm.049,11.539,2.188-6.408,2.114,6.408Z"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </a>
                  </div>
                  <div className="thankyou-box-title">
                    Thank you for requesting a price
                  </div>
                  <div className="thankyou-box-text">
                    One of our approved retailers will contact you with a price
                    for this door design.
                  </div>

                  {!this.props.match.params.iframePartnerPath ? (
                    <div className="window-system-box">
                      <div className="window-system-title">
                        {this.state.settings.final_page_ad_heading}
                      </div>
                      <div className="window-system-img">
                        <img
                          src={this.state.settings.final_page_ad_image}
                          alt=""
                        />
                      </div>
                      <div className="window-system-btn">
                        <a href={this.state.settings.final_page_ad_button_link}>
                          {this.state.settings.final_page_ad_button_title}
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  className="panel-title mobile mb"
                  onClick={(event) => {
                    event.preventDefault()
                    this.props.setSeeMyDoor(true)
                  }}
                >
                  VIEW ON MY HOUSE
                </div>
                <div className="details-form-door-preview">
                  <img
                    src={
                      require('../../../assets/images/details-form-door-preview.png')
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="see-inside-view">
                  <button type="button">SEE INSIDE VIEW</button>
                </div>
                <div className="share-my-door-btn">
                  <a>
                    SHARE MY DOOR ON SOCIAL
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.159"
                      height="9.809"
                      viewBox="0 0 11.159 9.809"
                    >
                      <line
                        id="Линия_25"
                        data-name="Линия 25"
                        x2="9.952"
                        transform="translate(0.5 4.905)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        stroke-width="1"
                      />
                      <line
                        id="Линия_26"
                        data-name="Линия 26"
                        x2="4.192"
                        y2="4.198"
                        transform="translate(6.26 0.707)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        stroke-width="1"
                      />
                      <line
                        id="Линия_27"
                        data-name="Линия 27"
                        y1="4.198"
                        x2="4.192"
                        transform="translate(6.26 4.904)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        stroke-width="1"
                      />
                    </svg>
                  </a>
                </div>
                <div className="thankyou-box-footer">
                  <div className="thankyou-box-description">
                    If you would like to speak with us, please contact our
                    customer services team.
                  </div>
                  <div className="thankyou-box-email">
                    <a href="">sales@apeer.co.uk</a>
                  </div>
                  <div className="thankyou-box-phone">
                    <a href="tel:03456729333">(UK) 034 5672 9333</a>
                    <a href="tel:04825632200">(ROI) 048 2563 2200</a>
                  </div>
                  <div className="thankyou-box-address">
                    Lines open 8am - 5pm Mon-Fri
                  </div>
                  <div className="thankyou-box-logo">
                    <a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="92.526"
                        height="40.455"
                        viewBox="0 0 92.526 40.455"
                      >
                        <g
                          id="Сгруппировать_788"
                          data-name="Сгруппировать 788"
                          transform="translate(-15648.237 -1531.806)"
                        >
                          <path
                            id="Контур_124"
                            data-name="Контур 124"
                            d="M15648.237,1531.806v40.455h10.065v-34.227l12.528-6.228Z"
                            fill="#fff"
                          />
                          <path
                            id="Контур_125"
                            data-name="Контур 125"
                            d="M15691.606,1546.777a4.76,4.76,0,0,0-4.317,2.359h-.055v-1.944h-3.782v20.617h3.978v-7.255h.057a5.069,5.069,0,0,0,4.344,2.3c4.231,0,6.363-3.8,6.363-7.875,0-4.336-2.046-8.2-6.587-8.2m-.841,12.92c-2.579,0-3.477-2.359-3.477-4.838s.842-4.924,3.449-4.924c2.55,0,3.474,2.5,3.474,4.924,0,2.479-.841,4.838-3.446,4.838"
                            fill="#fff"
                          />
                          <path
                            id="Контур_126"
                            data-name="Контур 126"
                            d="M15706.992,1546.777c-4.4,0-7.4,3.481-7.4,8.053,0,4.72,2.831,8.024,7.4,8.024,3.277,0,5.661-1.533,6.781-5.135h-3.5a3.261,3.261,0,0,1-3.137,1.978c-2.215,0-3.449-1.208-3.559-3.864h10.452c.279-4.691-2.1-9.056-7.034-9.056m-3.418,6.4a3.221,3.221,0,0,1,3.306-3.242c1.934,0,2.8,1.118,3.166,3.242Z"
                            fill="#fff"
                          />
                          <path
                            id="Контур_127"
                            data-name="Контур 127"
                            d="M15722.824,1546.777c-4.4,0-7.4,3.481-7.4,8.053,0,4.72,2.831,8.024,7.4,8.024,3.278,0,5.661-1.533,6.782-5.135h-3.5a3.265,3.265,0,0,1-3.137,1.978c-2.214,0-3.448-1.208-3.561-3.864h10.453c.279-4.691-2.1-9.056-7.034-9.056m-3.419,6.4a3.222,3.222,0,0,1,3.306-3.242c1.934,0,2.8,1.118,3.169,3.242Z"
                            fill="#fff"
                          />
                          <path
                            id="Контур_128"
                            data-name="Контур 128"
                            d="M15739.9,1546.777a5.123,5.123,0,0,0-4.624,3.247h-.059v-2.834h-3.782v15.25h3.981v-6.871c0-2.685,1.007-4.9,3.923-4.9a6.916,6.916,0,0,1,1.428.151v-3.9a2.874,2.874,0,0,0-.867-.147"
                            fill="#fff"
                          />
                          <path
                            id="Контур_129"
                            data-name="Контур 129"
                            d="M15670.5,1541.37l-7.605,21.052h4.469l2.189-6.639h6.174l2.214,6.591h4.593l-7.487-21Zm.049,11.539,2.188-6.408,2.114,6.408Z"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SimpleBar>
        </BuildItemsConstructor>

        <div className={'build__bottpanel stage-2 stage-3 hide'}>
          <div className="build__bottpanel-btnback">
            <a className="btn-1" href="#"></a>
          </div>

          <div className="build__bottpanel-btnsummary"></div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    allSettings: () => {
      return dispatch(allSettings())
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ThankYou))
