import React from "react";

class HeaderInnerLogout extends React.Component {
  render() {
    return (
      <div className="header__inner-logout build-door">
        <a href="#">IAN WELLS - LOG OUT</a>
      </div>
    );
  }
}

export default HeaderInnerLogout;
