import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHomeSecond from "../BuildDoor/BackHomeSecond";
import SeeOnMyHouseBottpanel from "./SeeOnMyHouseBottpanel";
import SeeOnMyHouseSecondPanel from "./SeeOnMyHouseSecondPanel";
import SeeOnMyHouseSecondPreview from "./SeeOnMyHouseSecondPreview";

class SeeOnMyHouseSecond extends React.Component {
  render() {
    return (
      <Main header={true}>
        <MainBoxAsite>
          <BackHomeSecond title="BACK TO DOOR STYLES" />
        </MainBoxAsite>
        <div className="main-box-content">
          <div className="summary__title white ">See on my house</div>
          <div className="main-box-content__inner overflow">
            <SeeOnMyHouseSecondPanel />
            <SeeOnMyHouseSecondPreview />
          </div>
          <SeeOnMyHouseBottpanel />
        </div>
      </Main>
    );
  }
}

export default SeeOnMyHouseSecond;
