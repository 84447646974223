import React from "react";

class HeaderMenuBtn extends React.Component {
  render() {
    return (
      <div
        className={
          "header__menu-btn " + (this.props.menuDropDownActive ? "open" : "")
        }
        onClick={() => {
          this.props.menuDropDownToggle();
        }}
      >
        <span />
        <span />
        <span />
        <span />
      </div>
    );
  }
}

export default HeaderMenuBtn;
