import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

function Loader(props) {
  return (
    <>
      <div className={"loader " + (props.status ? "show" : "")}>
        <PropagateLoader color={"#ffffff"} />
      </div>
    </>
  );
}
export default Loader;
