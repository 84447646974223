import React from "react";

class LoginWrapper extends React.Component {
  render() {
    return (
      <div className="login__wrapper">
        <div className="login">
          <div className="login-title arrow-icon">Log In</div>
          <form
            className="login-form"
            onSubmit={(event) => {
              event.preventDefault();
              this.props.login();
            }}
          >
            <div className="login-form-input">
              <label>EMAIL</label>
              <input
                type="text"
                className="global-input-text"
                value={this.props.formData.email}
                onChange={(event) => {
                  this.props.onChangeEmail(event);
                }}
                required
              />
              <a href="#">FORGOTTEN EMAIL</a>
            </div>
            <div className="login-form-input">
              <label>PASSWORD</label>
              <input
                type="password"
                className="global-input-text"
                value={this.props.formData.password}
                onChange={(event) => {
                  this.props.onChangePassword(event);
                }}
                required
              />
              <a href="#">FORGOTTEN PASSWORD</a>
            </div>
            <div className="login-form-btn">
              <button type="submit">LOG IN</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default LoginWrapper;
