const initializeState = {
  mainUrl: 'https://www.apeer.skander.pro',
}

export default function settingsReducer(state = initializeState, action) {
  switch (action.type) {
    default:
      return { ...state }
  }
}
