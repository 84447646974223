import React from "react";

class BuildDoorBoxItemOnlyImg extends React.Component {
  render() {
    return (
      <div className="build-door__box-item">
        <a href="#">
          <div className="build-door__box-item-img">
            <img src={require("../../assets/images/AP_Trad.jpg").default} alt />
          </div>
        </a>
        <div className="build-btn popup-btn">
          <div className="build-btn-img">
            <img
              src={require("../../assets/images/arrow-black.svg").default}
              alt
            />
          </div>
          <div className="build-btn-text">VIEW</div>
          <a href="/door-style"></a>
        </div>
      </div>
    );
  }
}

export default BuildDoorBoxItemOnlyImg;
