import React from "react";

class TitleConstructor extends React.Component {
  render() {
    return (
      <div
        className={
          "summary__title" +
          " " +
          (this.props.classAdd !== undefined ? this.props.classAdd : "")
        }
      >
        {this.props.title}
      </div>
    );
  }
}

export default TitleConstructor;
