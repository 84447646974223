import axios from 'axios'

export function saveOrder(formData, partnerPath) {
  console.log(formData)
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.post(mainUrl + `/api/orders/create`, {
      ...formData,
      partnerPath: partnerPath,
    })
    return response
  }
}

export function retrieveOrders() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(mainUrl + `/api/orders/retrieve-all`)
    return response
  }
}

export function retrieveOrder(id) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(mainUrl + `/api/orders/retrieve/${id}`)
    return response
  }
}

export function saveOrderProduct(formData) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.post(mainUrl + `/api/orders/create-product`, {
      ...formData,
    })
    return response
  }
}
