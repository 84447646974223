import React from "react";

class FinishDoorBtn extends React.Component {
  render() {
    return (
      <div className="finish-door-btn">
        <a
          className="global-btn-big "
          href="#"
          onClick={(e) => {
            e.preventDefault();
            this.props.greenButtonHandler();
          }}
        >
          FINISH MY DOOR
        </a>
      </div>
    );
  }
}

export default FinishDoorBtn;
