import React, { Component } from "react";
import TitleConstructor from "../../ElementsConstructor/TitleConstructor";
import MobileTitleConstructorSecond from "../../ElementsConstructor/MobileTitleConstructorSecond";
import BuildItemsConstructor from "../../ElementsConstructor/BuildItemsConstructor";
import BuildItemsInnerBoxConstructorMain from "../../ElementsConstructor/BuildItemsInnerBoxConstructorMain";
import FormFooterConstructorSecond from "../../ElementsConstructor/FormFooterConstructorSecond";

class GlassFrame extends Component {
  componentDidMount() {
    this.props.loadFrameGlasses();
  }

  render() {
    let frameTop = this.props.findLayer("frame-glasses-top");
    let frameSide = this.props.findLayer("frame-glasses-side");

    return (
      <>
        <TitleConstructor title="Glass design" classAdd="white" />
        <MobileTitleConstructorSecond title="You can have matching glass in your door and sidelights" />
        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructorMain
            classAdd="duplicate medium-height"
            classAddBox="mobile col2"
          >
            {this.props.frameGlasses.length == 0 ? (
              <div>{"No glasses"}</div>
            ) : null}
            {this.props.frameGlasses.map((glass, index) => {
              return (
                <div
                  className={"build__item build__marker-1 "}
                  key={`glass_index_${index}`}
                  onClick={() => {
                    this.props.chooseFrameGlass(glass);
                  }}
                >
                  <div className="build__item-img">
                    <img src={glass.img} />
                  </div>
                  <div className="build__item-name">{glass.code}</div>
                </div>
              );
            })}
          </BuildItemsInnerBoxConstructorMain>
        </BuildItemsConstructor>
        <FormFooterConstructorSecond
          link="/frame-style"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
        />
      </>
    );
  }
}
export default GlassFrame;
