import React from "react";
import HeaderMenu from "./HeaderMenu";
import SimpleBar from "simplebar-react";
class HeaderMenuDropdown extends React.Component {
  render() {
    return (
      <div
        className={
          "header__menu-dropdown " +
          (this.props.menuDropDownActive ? "active" : "")
        }
        onClick={() => {
          this.menuDropDownToggle();
        }}
      >
        <div className="header__menu-dropdown-box">
          <SimpleBar className="scrollable-area" forceVisible="y">
            <div className="header__menu-dropdown-inner ">
              <HeaderMenu></HeaderMenu>
              <div className="header__inner-logout build-door">
                <a href="#">IAN WELLS - LOG OUT</a>
              </div>
              <div className="header__inner-info">
                <span href="#">PROJECT REF NO. 0000007</span>
                <span href="#">DATE MODIFIED: 10/10/21</span>
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
    );
  }
}

export default HeaderMenuDropdown;
