export const INIT_SETTINGS = "INIT_SETTINGS";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const SET_USER_TOKEN = "SET_USER_TOKEN";

export const ADD_LOCAL_CART = "ADD_LOCAL_CART";
export const REMOVE_LOCAL_CART = "REMOVE_LOCAL_CART";
export const ADD_REMOTE_CART = "ADD_REMOTE_CART";
export const GET_LOCAL_CART = "GET_LOCAL_CART";
export const REMOVE_REMOTE_CART = "REMOVE_REMOTE_CART";
export const GET_REMOTE_CART = "GET_REMOTE_CART";
