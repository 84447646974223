import React from "react";

class MainTitle extends React.Component {
  render() {
    return (
      <div className="main-title">
        <div className="container">
          <h1>Welcome to Doorbuilder</h1>
        </div>
      </div>
    );
  }
}

export default MainTitle;
