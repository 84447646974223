import React from "react";

class SeeOnMyHouseBottpanel extends React.Component {
  render() {
    return (
      <div
        className={
          "build__bottpanel bg-gray " +
          (!this.props.myDoorEditor ? "functional-btn-hidden" : "")
        }
      >
        <div className="build__bottpanel-btnfs air-2">
          <a
            className="air-link"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              this.props.hideSeeMyDoor();
            }}
          >
            CLOSE PREVIEW
          </a>
        </div>
      </div>
    );
  }
}

export default SeeOnMyHouseBottpanel;
