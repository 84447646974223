/* global $ */
import React from "react";
import { withRouter } from "react-router-dom";

class FormFooterConstructorSecond extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div
        className={
          "build__bottpanel stage-2" +
          " " +
          (this.props.classAdd !== undefined ? this.props.classAdd : "")
        }
      >
        <div className="build__bottpanel-btnback">
          <a
            className="btn-1"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.props.backButtonHandler();
            }}
          >
            {"< BACK"}
          </a>
        </div>
        <div className="build__bottpanel-btnfs">
          <button
            className="btn-1"
            onClick={() => {
              this.props.greenButtonHandler();
            }}
          ></button>
        </div>
        <div className="build__bottpanel-btnsummary">
          <a
            className="btn-2"
            href="#"
            onClick={(event) => {
              if ($(window).width() < 500) {
                this.props.setShowImageMobile(true);
              } else {
                this.props.setSeeMyDoor(true);
              }
            }}
          >
            {"NEXT >"}
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(FormFooterConstructorSecond);
