import React from "react";
import MainBoxContentInnerImg from "../MainBox/Index/MainBoxContentInnerImg";
import MainTitle from "../MainBox/Index/MainTitle";

class MainBoxHome extends React.Component {
  render() {
    return (
      <div className="main-box-content">
        <MainTitle />
        <div className="main-box-content__inner ">
          <div className="main-box-content__inner-container scrollable-area">
            <div className="container ">{this.props.children}</div>
          </div>
          <MainBoxContentInnerImg />
        </div>
      </div>
    );
  }
}

export default MainBoxHome;
