/* global $ */
import React from "react";
import { withRouter } from "react-router-dom";

class FormFooterConstructorFrameStyle extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div
        className={
          "build__bottpanel stage-2" +
          " " +
          (this.props.classAdd !== undefined ? this.props.classAdd : "")
        }
      >
        <div className="build__bottpanel-btnback">
          <a
            className="btn-1"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.props.backButtonHandler();
            }}
          >
            {"< BACK"}
          </a>
        </div>
        <div className="build__bottpanel-btnfs">
          {/*{this.props.showImageMobile ? (*/}
          {/*  <button className="btn-1" onClick={() => {}} />*/}
          {/*) : null}*/}
        </div>
        <div className="build__bottpanel-btnsummary">
          {$(window).width() <= 500 ? (
            <a
              className="btn-2"
              href="#"
              onClick={(event) => {
                this.props.greenButtonHandler();
              }}
            >
              {"NEXT >"}
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(FormFooterConstructorFrameStyle);
