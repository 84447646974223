import React from "react";
import { Link, withRouter } from "react-router-dom";

function NavConstructor(props) {
  return (
    <div
      className={`summary__panel ${
        props.page == "my-dream-door" || props.page == "thank-you"
          ? "summary__panel--hiden"
          : ""
      }`}
    >
      <div
        className={`summary__panel-item ${
          props.page == "door_style" ? "active" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          props.navigateConstructorNav("door_style", true);
        }}
      >
        <a href={""}>
          DOOR <br></br> STYLE
        </a>
      </div>
      <div
        className={`summary__panel-item ${
          props.page == "frame_style" ? "active" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          props.navigateConstructorNav("frame_style", true);
        }}
      >
        <a href={""}>
          FRAME<br></br> STYLE
        </a>
      </div>
      <div
        className={`summary__panel-item ${
          props.page == "outside-door-color" ||
          props.page == "outside-frame-color"
            ? "active"
            : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          props.navigateConstructorNav("outside-door-color", true);
        }}
      >
        <a href={""}>
          OUTSIDE<br></br> COLOURS
        </a>
      </div>
      <div
        className={`summary__panel-item ${
          props.page == "inside-door-color" ||
          props.page == "inside-frame-color"
            ? "active"
            : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          props.navigateConstructorNav("inside-door-color", true);
        }}
      >
        <a href={""}>
          INSIDE<br></br> COLOUR
        </a>
      </div>
      <div
        className={`summary__panel-item ${
          props.page == "glass-design" || props.page == "glass-frame"
            ? "active"
            : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          props.navigateConstructorNav("glass-design", true);
        }}
      >
        <a href={""}>
          GLASS<br></br> DESIGN
        </a>
      </div>
      <div
        className={`summary__panel-item ${
          props.page == "furniture-type-handle" ||
          props.page == "furniture-color-handle" ||
          props.page == "furniture-type-knocker" ||
          props.page == "furniture-type-letterplate" ||
          props.page == "furniture-side-hinges" ||
          props.page == "furniture-group"
            ? "active"
            : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          props.navigateConstructorNav("furniture-type-handle", true);
        }}
      >
        <a href={""}>FURNITURE</a>
      </div>
    </div>
  );
}

export default withRouter(NavConstructor);
