import React from "react";
import TitleConstructor from "../../ElementsConstructor/TitleConstructor";
import MobileTitleConstructorSecond from "../../ElementsConstructor/MobileTitleConstructorSecond";
import FormFooterConstructorSecond from "../../ElementsConstructor/FormFooterConstructorSecond";
import BuildItemsConstructor from "../../ElementsConstructor/BuildItemsConstructor";
import BuildItemsInnerBoxConstructorMain from "../../ElementsConstructor/BuildItemsInnerBoxConstructorMain";
import FormFooterConstructorOutsideFrameColour from "./FormFooterConstructorOutsideFrameColour";
import PreviewDoor from "../../ElementsConstructor/PreviewDoor";
import PreviewFrameStyle from "../FrameStyleSecond/PreviewFrameStyle";
import PreviewOutsideFrameColour from "./PreviewOutsideFrameColour";

class OutsideFrameColour extends React.Component {
  componentDidMount() {
    this.props.setOrder("external");
    this.props.setShowImageMobile(false);
  }
  componentWillUnmount() {
    this.props.setOrder("internal");
  }
  render() {
    return (
      <>
        <TitleConstructor title="Outside frame colour" classAdd="white" />
        <MobileTitleConstructorSecond title="Your frame can be the same colour or a different colour to your door" />

        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructorMain
            classAdd="low-height"
            classAddBox="mobile col2"
          >
            {this.props.colors.map((color, index) => {
              let check = this.props.checkLayerInModelColor(
                this.props.partCategory,
                color,
                this.props.order
              );

              return (
                <div
                  className={
                    "build__item build__marker-2 " +
                    (check.length > 0 ? "active" : "")
                  }
                  key={`color_${index}`}
                  onClick={() => {
                    this.props.chooseColorPart(
                      this.props.partCategory,
                      color,
                      this.props.order
                    );
                  }}
                >
                  <div
                    className="build__item-img"
                    style={{
                      backgroundColor: color.hex,
                    }}
                  />
                  <div className="build__item-name">{color.name}</div>
                </div>
              );
            })}
          </BuildItemsInnerBoxConstructorMain>
        </BuildItemsConstructor>
        <FormFooterConstructorOutsideFrameColour
          link="/frame-style"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
        />
        <PreviewOutsideFrameColour
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          backButtonHandler={this.props.backButtonHandler}
          setShowImageMobile={this.props.setShowImageMobile}
          setOrder={this.props.setOrder}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
        />
      </>
    );
  }
}

export default OutsideFrameColour;
