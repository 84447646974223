import React from "react";

class BuildTitleHeader extends React.Component {
  render() {
    return (
      <div
        className={
          "build__title-header mb-none" +
          " " +
          (this.props.classAdd !== undefined ? this.props.classAdd : "")
        }
      >
        {this.props.title}
      </div>
    );
  }
}

export default BuildTitleHeader;
