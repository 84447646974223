import React, { Component } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { getBrands } from "../../redux/actions/brands";
import Loader from "../Loader/Loader";
import MainTitleBlackText from "../GeneralComponents/MainTitleBlackText";
import SimpleBar from "simplebar-react";
import { NavLink } from "react-router-dom";
import FavouriteStyleBannerSecond from "../ChooseYourFavouriteStyle/FavouriteStyleBannerSecond";
import FavouriteStyleFooter from "../ChooseYourFavouriteStyle/FavouriteStyleFooter";

class IFrameContainer extends Component {
  state = {
    list: [],
    status: true,
    brands: [],
  };
  componentDidMount() {
    this.props.getBrands().then((response) => {
      if (response.data.success) {
        this.setState({
          brands: [...response.data.data],
          status: false,
        });
      }
    });
  }

  render() {
    return (
      <Main header={false}>
        <div className="main-box-content">
          <div className="main-box-content__inner">
            <SimpleBar className="scrollable-area" forceVisible="y">
              <div className="main-box-content__inner-container">
                <div className="container container-height">
                  <div className="build-door__box favourite-style">
                    {this.state.brands.map((brand, index) => {
                      return (
                        <div
                          className="build-door__box-item"
                          key={`brand_${index}`}
                        >
                          <NavLink
                            to={`/admin-constructor/${brand.slug}/partner-id/${this.props.match.params.iframePartnerPath}`}
                          >
                            <div className="build-door__box-item-title">
                              {brand.name}
                            </div>
                            <div className="build-door__box-item-img">
                              <img src={brand.image_url} alt="" />
                            </div>
                            <div className="build-door__box-item-link">
                              Build Your Dream Door
                            </div>
                          </NavLink>
                          <div className="build-btn popup-btn">
                            <div className="build-btn-img">
                              <img
                                src={
                                  require("../../assets/images/arrow-black.svg")
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <div className="build-btn-text">LET’S BUILD</div>
                          </div>
                        </div>
                      );
                    })}
                    {/*<div className="build-door__box-item">*/}
                    {/*  <NavLink to={`/most-popular-doors`}>*/}
                    {/*    <div className="build-door__box-item-title">{}</div>*/}
                    {/*    <div className="build-door__box-item-img">*/}
                    {/*      <img*/}
                    {/*        src={*/}
                    {/*          require("./../../assets/images/AP_Access.jpg")*/}
                    {/*            .default*/}
                    {/*        }*/}
                    {/*        alt=""*/}
                    {/*      />*/}
                    {/*    </div>*/}
                    {/*    <div className="build-door__box-item-link">*/}
                    {/*      Build Your Dream Door*/}
                    {/*    </div>*/}
                    {/*  </NavLink>*/}
                    {/*  <div className="build-btn popup-btn">*/}
                    {/*    <div className="build-btn-img">*/}
                    {/*      <img*/}
                    {/*        src={*/}
                    {/*          require("../../assets/images/arrow-black.svg")*/}
                    {/*            .default*/}
                    {/*        }*/}
                    {/*        alt=""*/}
                    {/*      />*/}
                    {/*    </div>*/}
                    {/*    <div className="build-btn-text">LET’S BUILD</div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
        </div>
        <Loader status={this.state.status} />
      </Main>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getBrands: () => {
      return dispatch(getBrands());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(IFrameContainer);
