import React from "react";

import TitleConstructor from "../../ElementsConstructor/TitleConstructor";
import MobileTitleConstructorSecond from "../../ElementsConstructor/MobileTitleConstructorSecond";
import FormFooterConstructorSecond from "../../ElementsConstructor/FormFooterConstructorSecond";
import BuildItemsConstructor from "../../ElementsConstructor/BuildItemsConstructor";
import BuildItemsInnerBoxConstructorMain from "../../ElementsConstructor/BuildItemsInnerBoxConstructorMain";
import FormFooterConstructorGlassDesign from "./FormFooterConstructorGlassDesign";
import PreviewDoor from "../../ElementsConstructor/PreviewDoor";
import PreviewGlassDesign from "./PreviewGlassDesign";

class GlassDesign extends React.Component {
  componentDidMount() {
    this.props.loadGlassByModel();
    this.props.setShowImageMobile(false);
  }
  findLayer = (category) => {
    return this.props.model.find(
      (item) => item.category_part_slug === category
    );
  };

  render() {
    let layer = this.findLayer("door-style");
    return (
      <>
        <TitleConstructor title="Glass design" classAdd="white" />
        <MobileTitleConstructorSecond title="Click on a glass design to see it appear in your door" />

        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructorMain
            classAdd="duplicate medium-height"
            classAddBox="mobile col2"
          >
            {this.props.glasses.map((glass, index) => {
              return (
                <div
                  className={
                    "build__item build__marker-1 glass  " +
                    (this.props.glassActive === glass.id ? "active" : "")
                  }
                  key={`glass_index_${index}`}
                  onClick={() => {
                    this.props.chooseGlassDouble(glass);
                  }}
                >
                  <div className="build__item-img">
                    <img src={glass.preview} />
                  </div>
                  <div className="build__item-name">{glass.title}</div>
                </div>
              );
            })}
          </BuildItemsInnerBoxConstructorMain>
        </BuildItemsConstructor>
        <FormFooterConstructorGlassDesign
          link="/frame-style"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
        />
        <PreviewGlassDesign
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          setShowImageMobile={this.props.setShowImageMobile}
          backButtonHandler={this.props.backButtonHandler}
          setOrder={this.props.setOrder}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
        />
      </>
    );
  }
}

export default GlassDesign;
