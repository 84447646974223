import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHomeSecond from "../BuildDoor/BackHomeSecond";
import SeeOnMyHousePanelGallery from "./SeeOnMyHousePanelGallery";
import SeeOnMyHousePreview from "./SeeOnMyHousePreview";
import { withRouter } from "react-router-dom";
import SeeOnMyHouseSecondPanel from "./SeeOnMyHouseSecondPanel";
import SeeOnMyHousePreviewMobile from "./SeeOnMyHousePreviewMobile";

class SeeOnMyHouse extends React.Component {
  componentDidMount() {
    this.props.loadHouses();
  }

  render() {
    return (
      <Main header={!this.props.match.params.iframePartnerPath}>
        <MainBoxAsite>
          <BackHomeSecond title="BACK TO DOOR STYLES" />
        </MainBoxAsite>
        <div className="main-box-content">
          <div className="main-box-content__inner overflow">
            {this.props.myDoorEditor ? (
              <SeeOnMyHouseSecondPanel
                downloadHouse={this.props.downloadHouse}
                hideSeeMyDoor={this.props.hideSeeMyDoor}
              >
                {window.innerWidth <= 500 ? (
                  <SeeOnMyHousePreviewMobile
                    classAdd="mob-v"
                    activeHouse={this.props.activeHouse}
                    setMyDoorEditor={this.props.setMyDoorEditor}
                    myDoorEditor={this.props.myDoorEditor}
                    image={this.props.image}
                  />
                ) : null}
              </SeeOnMyHouseSecondPanel>
            ) : (
              <SeeOnMyHousePanelGallery
                houses={this.props.houses}
                setActiveHouse={this.props.setActiveHouse}
                activeHouse={this.props.activeHouse}
                hideSeeMyDoor={this.props.hideSeeMyDoor}
                setMyDoorEditor={this.props.setMyDoorEditor}
                myDoorEditor={this.props.myDoorEditor}
              />
            )}
            {window.innerWidth > 500 ? (
              <SeeOnMyHousePreview
                activeHouse={this.props.activeHouse}
                setMyDoorEditor={this.props.setMyDoorEditor}
                myDoorEditor={this.props.myDoorEditor}
                image={this.props.image}
                hideSeeMyDoor={this.props.hideSeeMyDoor}
              />
            ) : null}
          </div>
        </div>
      </Main>
    );
  }
}

export default withRouter(SeeOnMyHouse);
