import React from 'react'

function ModalWarning(props) {
  return (
    <div className="modal-warning">
      <div className="content">{props.message}</div>
      <div className="button">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            props.setStatus(false)
          }}
        >
          OK
        </a>
      </div>
    </div>
  )
}
export default ModalWarning
