import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHomeSecond from "../BuildDoor/BackHomeSecond";
import MainTitleBlackText from "../GeneralComponents/MainTitleBlackText";
import EnergyEfficiencyListPanel from "./EnergyEfficiencyListPanel";

import SimpleBar from "simplebar-react";
import FavouriteStyleFooter from "../ChooseYourFavouriteStyle/FavouriteStyleFooter";
class GlassDesigns extends React.Component {
  // componentDidMount() {
  // 	let hash = window.location.hash;
  // 	let element = document.getElementById(hash);
  // 	element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  // 	let hash = window.location.hash;
  // 	let element = document.getElementById(hash);
  // 	element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }

  render() {
    return (
      <Main header={true}>
        <MainBoxAsite>
          <BackHomeSecond title="BACK TO DOOR STYLES" />
        </MainBoxAsite>
        <div className="main-box-content">
          <MainTitleBlackText
            classAdd="main-title-stage favourite-style"
            children="Glass Designs"
          />

          <div className="main-box-content__inner column">
            <div className="energyEfficiency__wrapper">
              <SimpleBar className="scrollable-area" forceVisible="y">
                <div className="main-box-content__inner-container ">
                  <EnergyEfficiencyListPanel />
                  <div className="efficiency-info__wrapper">
                    <div className="efficiency-info__first">
                      <div className="efficiency-info__first-item">
                        <div className="efficiency-info__first-item-con">
                          <div className="efficiency-info__first-item-con__inner">
                            <div className="efficiency-info-title">
                              Add a personal touch with bespoke, handcrafted
                              glass designs.
                            </div>
                            <div className="efficiency-info__first-item-img">
                              <img
                                src={
                                  require("../../assets/images/energy-efficiency/Glass-Designs-1.jpg")
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <div className="efficiency-info-text-inner mb-0">
                              <div className="efficiency-info-text ">
                                You’ve chosen the door style and picked a colour
                                that perfectly complements your home. Now for
                                the finishing touch: glass designed by you and
                                handcrafted by our in-house artisan glaziers.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="efficiency-info__second">
                      <div className="efficiency-info__second-img-box height">
                        <div className="efficiency-info__second-img">
                          <img
                            src={
                              require("../../assets/images/energy-efficiency/Glass-Designs-2.jpg")
                                .default
                            }
                            alt=""
                          />
                        </div>
                        <div className="efficiency-info__second-img">
                          <img
                            src={
                              require("../../assets/images/energy-efficiency/Glass-Designs-3.jpg")
                                .default
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="efficiency-info__third">
                      <div className="efficiency-info-text">
                        <p>Clearly yours</p>
                        <div className="efficiency-info-text-mb">
                          <p>
                            Our in-house glaziers have pulled together some of
                            their favourite, on-trend designs. They are
                            available across our range and are an easy way to
                            make it clear that it’s more than just a door – it’s
                            a welcome new addition to your family.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FavouriteStyleFooter>
                    <div className="favourite-style-footer-backbtn">
                      <a href="/"> {"< BACK"} </a>
                    </div>
                  </FavouriteStyleFooter>
                </div>
              </SimpleBar>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default GlassDesigns;
