import React from "react";
import SimpleBar from "simplebar-react";
class MainBoxAsite extends React.Component {
  render() {
    return (
      <div
        className={
          "main-box-asite" +
          " " +
          (this.props.classAdd !== undefined ? this.props.classAdd : "")
        }
      >
        <SimpleBar className="scrollable-area" forceVisible="y">
          <div className="main-box-asite__inner">{this.props.children}</div>
        </SimpleBar>
      </div>
    );
  }
}

export default MainBoxAsite;
