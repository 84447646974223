import React, { Component } from "react";

class PreviewDoor extends Component {
  render() {
    return (
      <div
        className={
          "preview-door " + (this.props.showImageMobile ? "active" : "")
        }
      >
        <div className="preview-door__inner">
          {/* <div
						className="popup-close"
						onClick={() => {
							this.props.setShowImageMobile(false);
						}}
					>
						<div className="popup-close-img">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="11.273"
								height="11.303"
								viewBox="0 0 11.273 11.303"
							>
								<g
									id="Сгруппировать_680"
									data-name="Сгруппировать 680"
									transform="translate(-10657.854 -68.903)"
								>
									<g id="Сгруппировать_678" data-name="Сгруппировать 678">
										<path
											id="Контур_504"
											data-name="Контур 504"
											d="M10663.477,74.914a.36.36,0,0,0,.255-.106l5.292-5.292a.359.359,0,1,0-.508-.507l-5.293,5.292a.36.36,0,0,0,.254.613Z"
										/>
										<path
											id="Контур_505"
											data-name="Контур 505"
											d="M10668.768,80.206a.359.359,0,0,0,.255-.613l-5.291-5.292a.359.359,0,0,0-.508.507l5.291,5.293A.358.358,0,0,0,10668.768,80.206Z"
										/>
									</g>
									<g id="Сгруппировать_679" data-name="Сгруппировать 679">
										<path
											id="Контур_506"
											data-name="Контур 506"
											d="M10663.506,74.2a.359.359,0,0,0-.254.105l-5.293,5.293a.359.359,0,0,0,.508.507l5.292-5.293a.359.359,0,0,0-.253-.612Z"
										/>
										<path
											id="Контур_507"
											data-name="Контур 507"
											d="M10658.214,68.9a.36.36,0,0,0-.254.613l5.291,5.292a.359.359,0,1,0,.508-.507l-5.291-5.292A.354.354,0,0,0,10658.214,68.9Z"
										/>
									</g>
								</g>
							</svg>
						</div>
					</div> */}
          <div className="preview-door__title ">Outside door colour</div>
          <div className="preview-door__img ">
            <div className="preview-door__img-box">
              <img
                src={this.props.image}
                alt=""
                style={{
                  transform: `scale(${this.props.scale})`,
                }}
              />
            </div>
            {/* <div
							className="preview-door__img-btn"
							onClick={() => {
								let order =
									this.props.order === "external" ? "internal" : "external";
								this.props.setOrder(order);
							}}
						>
							<div className="preview-door__img-btn-item">
								<figure>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="8.662"
										height="6.31"
										viewBox="0 0 8.662 6.31"
									>
										<g
											id="Сгруппировать_681"
											data-name="Сгруппировать 681"
											transform="translate(-10460.521 -552.245)"
										>
											<path
												id="Контур_508"
												data-name="Контур 508"
												d="M10468.983,555.6h-8.262a.2.2,0,1,1,0-.4h8.262a.2.2,0,1,1,0,.4Z"
											/>
											<path
												id="Контур_509"
												data-name="Контур 509"
												d="M10468.983,555.6a.2.2,0,0,1-.142-.059l-2.955-2.954a.2.2,0,0,1,.284-.283l2.954,2.954a.2.2,0,0,1,0,.283A.2.2,0,0,1,10468.983,555.6Z"
											/>
											<path
												id="Контур_510"
												data-name="Контур 510"
												d="M10466.029,558.554a.2.2,0,0,1-.143-.059.2.2,0,0,1,0-.283l2.954-2.955a.2.2,0,0,1,.283,0,.2.2,0,0,1,0,.283l-2.953,2.955A.2.2,0,0,1,10466.029,558.554Z"
											/>
										</g>
									</svg>
								</figure>
								<div className="preview-door__img-btn-item-text">
									{this.props.order} VIEW
								</div>
							</div>
							<div
								className="preview-door__img-btn-item"
								onClick={() => {
									this.props.setScale();
								}}
							>
								<figure>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="8.662"
										height="8.662"
										viewBox="0 0 8.662 8.662"
									>
										<g
											id="Сгруппировать_683"
											data-name="Сгруппировать 683"
											transform="translate(-10541.219 -551.068)"
										>
											<path
												id="Контур_511"
												data-name="Контур 511"
												d="M10545.35,559.53v-8.262a.2.2,0,0,1,.4,0v8.262a.2.2,0,0,1-.4,0Z"
											/>
											<path
												id="Контур_512"
												data-name="Контур 512"
												d="M10541.42,555.2h8.261a.2.2,0,1,1,0,.4h-8.261a.2.2,0,1,1,0-.4Z"
											/>
										</g>
									</svg>
								</figure>
								<div className="preview-door__img-btn-item-text">ZOOM VIEW</div>
							</div>
						</div> */}
          </div>
          <div className="preview-door__label ">
            <div className="preview-door__label-text green">CHOOSE</div>
            <div className="preview-door__label-text">DOOR COLOUR</div>
          </div>
          {/* <div
						className="preview-door__close "
						onClick={() => {
							this.props.setShowImageMobile(false);
						}}
					>
						CLOSE PREVIEW
					</div> */}
        </div>
      </div>
    );
  }
}
export default PreviewDoor;
