import React from "react";

class MainTitleBlackText extends React.Component {
  render() {
    return (
      <div
        className={
          "main-title" +
          " " +
          (this.props.classAdd !== undefined ? this.props.classAdd : "")
        }
      >
        <div className="container">
          <h1>{this.props.children}</h1>
        </div>
      </div>
    );
  }
}

export default MainTitleBlackText;
