import React from "react";
import { Link } from "react-router-dom";

class HeaderInnerLogo extends React.Component {
  render() {
    return (
      <div className="header__inner-logo">
        <a href="/">
          <img
            src={require("../../../assets/images/logo.svg").default}
            alt={""}
          />
        </a>
      </div>
    );
  }
}

export default HeaderInnerLogo;
