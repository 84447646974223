import React from "react";
import TitleConstructor from "../../ElementsConstructor/TitleConstructor";
import MobileTitleConstructorSecond from "../../ElementsConstructor/MobileTitleConstructorSecond";
import FormFooterConstructorSecond from "../../ElementsConstructor/FormFooterConstructorSecond";
import BuildItemsConstructor from "../../ElementsConstructor/BuildItemsConstructor";
import BuildItemsInnerBoxConstructorMain from "../../ElementsConstructor/BuildItemsInnerBoxConstructorMain";
import BuildTitleHeader from "../../ElementsConstructor/BuildTitleHeader";
import FormFooterConstructorFurnitureTypeKnocker from "./FormFooterConstructorFurnitureTypeKnocker";
import PreviewDoor from "../../ElementsConstructor/PreviewDoor";
import PreviewFurnitureGroup from "../FurnitureGroup/PreviewFurnitureGroup";

class FurnitureTypeKnocker extends React.Component {
  componentDidMount() {
    this.props.loadKnockers();
    this.props.setShowImageMobile(false);
  }

  render() {
    return (
      <>
        <TitleConstructor title="Furniture" classAdd="white" />
        <MobileTitleConstructorSecond title="Aspire Range Choose your door knocker" />

        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructorMain
            classAdd="col-2 medium-height"
            classAddBox="mobile col2"
          >
            {this.props.knockers.map((knocker, index) => {
              return (
                <div
                  className={
                    "build__item build__marker-1 " +
                    (this.props.checkLayerInModel(knocker).length > 0
                      ? "active"
                      : "")
                  }
                  key={`knocker_${index}`}
                  onClick={() => {
                    this.props.chooseComponent(knocker);
                  }}
                >
                  <div className="build__item-img ">
                    <img src={knocker.preview} />
                  </div>
                  <div className="build__item-name">{knocker.name}</div>
                </div>
              );
            })}
          </BuildItemsInnerBoxConstructorMain>
        </BuildItemsConstructor>
        <FormFooterConstructorFurnitureTypeKnocker
          link="/frame-style-second"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
        />
        <PreviewFurnitureGroup
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          setShowImageMobile={this.props.setShowImageMobile}
          setOrder={this.props.setOrder}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
          backButtonHandler={this.props.backButtonHandler}
        />
      </>
    );
  }
}

export default FurnitureTypeKnocker;
