import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHomeSecond from "../BuildDoor/BackHomeSecond";
import MainTitleBlackText from "../GeneralComponents/MainTitleBlackText";

import EnergyEfficiencyContent from "./EnergyEfficiencyContent";

class EnergyEfficiency extends React.Component {
  // componentDidMount() {
  // 	let hash = window.location.hash;
  // 	let element = document.getElementById(hash);
  // 	element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  // 	let hash = window.location.hash;
  // 	let element = document.getElementById(hash);
  // 	element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }

  render() {
    return (
      <Main header={true}>
        <MainBoxAsite>
          <BackHomeSecond title="BACK TO DOOR STYLES" />
        </MainBoxAsite>
        <div className="main-box-content">
          <MainTitleBlackText
            classAdd="main-title-stage favourite-style"
            children="Energy Efficiency"
          />

          <div className="main-box-content__inner column">
            <EnergyEfficiencyContent />
          </div>
        </div>
      </Main>
    );
  }
}

export default EnergyEfficiency;
