import React from "react";
import { Link } from "react-router-dom";

class FavouriteStyleFooter extends React.Component {
  render() {
    return <div className="favourite-style-footer">{this.props.children}</div>;
  }
}

export default FavouriteStyleFooter;
