import React from "react";

class ImageConstructor extends React.Component {
  nextPage = () => {
    if (this.props.page === "door_style") {
      this.props.nextDoorStyle();
    }
    if (this.props.page === "frame_style") {
      this.props.nextFrameStyle();
    }
    if (this.props.page === "outside-door-color") {
      this.props.nextOutsideDoorColor();
    }
    if (this.props.page === "outside-frame-color") {
      this.props.nextOutsideFrameColor();
    }
    if (this.props.page === "inside-door-color") {
      this.props.nextInsideDoorColor();
    }
    if (this.props.page === "inside-frame-color") {
      this.props.nextInsideFrameColor();
    }
    if (this.props.page === "glass-design") {
      this.props.nextGlassDesign();
    }
    if (this.props.page === "furniture-group") {
      this.props.nextFurnitureSideHinges();
    }
    if (this.props.page === "furniture-type-handle") {
      this.props.nextFurnitureTypeHandle();
    }
    if (this.props.page === "furniture-color-handle") {
      this.props.nextFurnitureColorHandle();
    }
    if (this.props.page === "furniture-type-knocker") {
      this.props.nextFurnitureTypeKnocker();
    }
    if (this.props.page === "furniture-type-letterplate") {
      this.props.nextFurnitureTypeLetterplate();
    }
    if (this.props.page === "furniture-side-hinges") {
      this.props.nextFurnitureSideHinges();
    }
  };

  render() {
    return (
      <div
        className="summary__img-box"
        style={{
          backgroundImage: `url(${this.props.image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center center",
          //  marginTop: 10,
          transform: `scale(${this.props.scale})`,
          backgroundColor: this.props.backgroundColor,
        }}
      >
        {this.props.children}
        <button
          className="summary__img-box-btnNext "
          onClick={() => {
            this.nextPage();
          }}
        >
          NEXT
        </button>
      </div>
    );
  }
}

export default ImageConstructor;
